<template>
  <v-form
    :lazy-validation="false"
    @submit.prevent="handleSubmit"
    v-model="valid"
    ref="form"
    :loading="
      alarmStatus.loading || tagDataStatus.loading || companyStatus.loading
    "
  >
    <DeleteDialog
      :dialog="dialog"
      v-on:confirm-delete="deleteItem"
      v-on:cancel-dialog="dialog = false"
    />
    <v-card>
      <v-toolbar color="primary" dark flat>
        <v-toolbar-title>{{ $t("alarm.create") }}</v-toolbar-title>
        <div class="flex-grow-1"></div>
      </v-toolbar>

      <v-card-text>
        <v-text-field
          :label="$t('alarm.fields.name')"
          name="name"
          prepend-icon="mdi-domain"
          type="text"
          v-model="name"
          :rules="nameRules"
        ></v-text-field>
      </v-card-text>

      <v-card-text>
        <v-text-field
          :label="$t('alarm.fields.message')"
          name="message"
          prepend-icon="mdi-message"
          type="text"
          v-model="message"
          :rules="messageRules"
        ></v-text-field>
      </v-card-text>

      <v-card-text>
        <v-autocomplete
          autocomplete="off"
          :label="$t('alarm.fields.activeDays')"
          v-model="activeDays"
          :items="days"
          item-text="name"
          item-value="value"
          prepend-icon="mdi-calendar-today"
          :rules="autocompleteRules"
          required
          multiple
        ></v-autocomplete>
      </v-card-text>

      <v-card-text>
        <v-autocomplete
          autocomplete="off"
          :label="$t('alarm.fields.alarmContacts')"
          v-model="alarmContacts"
          :items="companyUsers"
          :rules="autocompleteRules"
          required
          item-text="firstname"
          item-value="userId"
          multiple
          prepend-icon="mdi-account-box"
        >
          <template v-slot:item="data">
            <template v-if="isNotObject(data.item)">
              <v-list-item-content v-text="data.item"></v-list-item-content>
            </template>
            <template v-else>
              <v-list-item-content>
                <v-list-item-title
                  v-html="data.item.firstname"
                ></v-list-item-title>
                <v-list-item-subtitle
                  v-html="data.item.lastname"
                ></v-list-item-subtitle>
              </v-list-item-content>
            </template>
          </template>
        </v-autocomplete>
      </v-card-text>

      <v-card-text>
        <v-switch
          v-model="advanced"
          :label="$t('alarm.fields.advanced')"
        ></v-switch>
      </v-card-text>

      <v-card-text v-if="!advanced">
        <v-autocomplete
          autocomplete="off"
          :loading="tagStatus.loading"
          :label="$t('alarm.fields.tag')"
          v-model="tag"
          :items="tags"
          :rules="autocompleteRules"
          required
          item-text="name"
          item-value="deveui"
          prepend-icon="mdi-cellphone-link"
        ></v-autocomplete>
      </v-card-text>

      <v-card-text v-if="!advanced">
        <v-autocomplete
          autocomplete="off"
          :disabled="tagStatus.loading && tag === ''"
          :loading="tagDataStatus.loading"
          :label="$t('alarm.fields.tagDataKey')"
          v-model="tagDataKey"
          :items="keys"
          :rules="autocompleteRules"
          required
          item-text="label"
          item-value="key"
          prepend-icon="mdi-key"
        ></v-autocomplete>
      </v-card-text>

      <v-card-text v-if="!advanced">
        <v-autocomplete
          autocomplete="off"
          :items="signalTypes"
          v-model="signalType"
          :label="$t('alarm.fields.signalType')"
          required
          item-text="name"
          item-value="value"
          prepend-icon="mdi-wave"
        ></v-autocomplete>
      </v-card-text>

      <div v-if="signalType == 0 && !advanced">
        <v-card-text>
          <v-container fluid>
            <v-row justify="space-between">
              <v-col cols="12" sm="6" md="6">
                <v-text-field
                  :label="$t('alarm.fields.limitLow')"
                  type="number"
                  v-model="limitLow"
                  single-line
                  prepend-icon="mdi-priority-low"
                />
              </v-col>

              <v-spacer></v-spacer>
              <v-col cols="12" sm="6" md="6">
                <v-text-field
                  :label="$t('alarm.fields.limitHigh')"
                  type="number"
                  v-model="limitHigh"
                  single-line
                  prepend-icon="mdi-priority-high"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </div>

      <v-card-text v-if="signalType == 1 && !advanced">
        <v-autocomplete
          autocomplete="off"
          :label="$t('alarm.fields.digitalTrigger')"
          v-model="digitalType"
          :items="digitalTypes"
          required
          item-text="name"
          item-value="value"
          prepend-icon="mdi-electric-switch"
        ></v-autocomplete>
      </v-card-text>

      <v-card-text>
        <v-subheader>{{ $t("alarm.fields.alarmDelay") }}</v-subheader>
        <v-slider
          v-model="alarmDelay"
          class="align-center"
          :max="delayMax"
          :min="delayMin"
          hide-details
        >
          <template v-slot:append>
            <v-text-field
              v-model="alarmDelay"
              class="mt-0 pt-0"
              hide-details
              single-line
              type="number"
              style="width: 60px"
            ></v-text-field>
          </template>
        </v-slider>
      </v-card-text>

      <v-card-text>
        <v-container fluid>
          <v-row align="center" justify="space-around">
            <v-col cols="12" sm="10" md="10">
              <v-select
                v-model="priority"
                :items="priorityItems"
                :label="$t('alarm.fields.priority')"
                item-text="name"
                item-value="value"
              >
                <template v-slot:item="data">
                  <template v-if="isNotObject(data.item)">
                    <v-list-item-content
                      v-text="data.item"
                    ></v-list-item-content>
                  </template>
                  <template v-else>
                    <v-list-item-content>
                      <v-list-item-title
                        v-html="data.item.name"
                      ></v-list-item-title>
                      <v-list-item-subtitle
                        v-html="data.item.desc"
                      ></v-list-item-subtitle>
                    </v-list-item-content>
                  </template>
                </template>
              </v-select>
              <v-select
                v-model="alertType"
                :items="alertTypeItems"
                :label="$t('alarm.fields.alertType')"
                item-text="name"
                item-value="value"
              >
                <template v-slot:item="data">
                  <template v-if="isNotObject(data.item)">
                    <v-list-item-content
                      v-text="data.item"
                    ></v-list-item-content>
                  </template>
                  <template v-else>
                    <v-list-item-content>
                      <v-list-item-title
                        v-html="data.item.name"
                      ></v-list-item-title>
                      <v-list-item-subtitle
                        v-html="data.item.desc"
                      ></v-list-item-subtitle>
                    </v-list-item-content>
                  </template>
                </template>
              </v-select>
              <v-checkbox
                v-model="autoAcknowledge"
                :label="$t('alarm.fields.autoAcknowledge')"
              ></v-checkbox>
            </v-col>
            <v-col cols="12" sm="2" md="2">
              <v-switch
                v-model="active"
                :label="$t('alarm.fields.active')"
              ></v-switch>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>

      <v-card-text v-if="advanced">
        <v-select
          v-model="logicalOperator"
          :items="[{ name: 'Or', value: 0 }, { name: 'And', value: 1 }]"
          :label="$t('alarm.fields.logicalOperator')"
          item-text="name"
          item-value="value"
        ></v-select>
        <condition-tree v-model="advancedAlarms" :logical-operator="logicalOperator" />
      </v-card-text>

      <v-card-actions>
        <v-btn color="primary" type="submit" :disabled="!valid" x-large>{{
          $t("common.save")
        }}</v-btn>

        <v-btn
          color="secondary"
          type="button"
          x-large
          v-on:click.native="copyAlarm()"
          >{{ $t("common.copy") }}
        </v-btn>

        <v-spacer />

        <v-btn
          color="error"
          type="button"
          x-large
          v-on:click.native="deleteAlarm()"
          >{{ $t("common.delete") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-form>
</template>

<script>
import { mapActions, mapState } from "vuex";
import i18n from "@/plugins/i18n";
import CsharpEnum from "@/_helpers/CsharpEnum";
import DeleteDialog from "@/components/common/DeleteDialog";
import ConditionTree from "../conditionTree/ConditionTree.vue";

export default {
  name: "AlarmEdit",
  data() {
    return {
      submitted: false,
      valid: false,
      dialog: false,
      max: 80,
      min: -50,
      delayMax: 120,
      delayMin: 0,

      logicalOperator: 0,
      alarmId: "",
      name: "",
      priority: CsharpEnum.Priority.A,
      alertType: CsharpEnum.AlertType.A,
      autoAcknowledge: false,
      alarmDelay: 10,
      active: true,
      advanced: false,
      tagDataKey: "",
      tag: "",
      message: "",
      limitLow: null,
      limitHigh: null,
      contactType: CsharpEnum.ContactType.BOTH,
      signalType: null,
      digitalType: null,
      alarmContacts: [],
      activeDays: [],
      advancedAlarms: [],

      days: [
        {
          name: i18n.t("common.days.monday"),
          value: CsharpEnum.DaysOfWeek.monday,
        },
        {
          name: i18n.t("common.days.tuesday"),
          value: CsharpEnum.DaysOfWeek.tuesday,
        },
        {
          name: i18n.t("common.days.wednesday"),
          value: CsharpEnum.DaysOfWeek.wednesday,
        },
        {
          name: i18n.t("common.days.thursday"),
          value: CsharpEnum.DaysOfWeek.thursday,
        },
        {
          name: i18n.t("common.days.friday"),
          value: CsharpEnum.DaysOfWeek.friday,
        },
        {
          name: i18n.t("common.days.saturday"),
          value: CsharpEnum.DaysOfWeek.saturday,
        },
        {
          name: i18n.t("common.days.sunday"),
          value: CsharpEnum.DaysOfWeek.sunday,
        },
      ],

      alertTypeItems: [
        {
          name: i18n.t("alarm.alertType.desc.a"),
          value: CsharpEnum.AlertType.A,
        },
        {
          name: i18n.t("alarm.alertType.desc.b"),
          value: CsharpEnum.AlertType.B,
        },
        {
          name: i18n.t("alarm.alertType.desc.c"),
          value: CsharpEnum.AlertType.C,
        },
        {
          name: i18n.t("alarm.alertType.desc.d"),
          value: CsharpEnum.AlertType.D,
        },
      ],

      priorityItems: [
        {
          name: i18n.t("alarm.priority.desc.a"),
          value: CsharpEnum.Priority.A,
        },
        {
          name: i18n.t("alarm.priority.desc.b"),
          value: CsharpEnum.Priority.B,
        },
        {
          name: i18n.t("alarm.priority.desc.c"),
          value: CsharpEnum.Priority.C,
        },
      ],

      signalTypes: [
        {
          name: "Analog",
          value: 0,
        },
        {
          name: "Digital",
          value: 1,
        },
      ],

      digitalTypes: [
        {
          name: i18n.t("alarm.fields.triggerHigh"),
          value: 0,
        },
        {
          name: i18n.t("alarm.fields.triggerLow"),
          value: 1,
        },
      ],

      nameRules: [
        (v) => !!v || this.$t("alarm.validation.nameRequired"),
        (v) => (v && v.length <= 255) || this.$t("alarm.validation.max"),
      ],

      messageRules: [
        (v) => !!v || this.$t("alarm.validation.nameRequired"),
        (v) => (v && v.length <= 255) || this.$t("alarm.validation.max"),
      ],

      autocompleteRules: [
        (v) => !!v || this.$t("alarm.validation.nameRequired"),
      ],
    };
  },

  computed: {
    ...mapState("users", [
      "status",
      "currentUser",
      "userCompany",
      "hasCompany",
    ]),
    ...mapState("tag", { tagStatus: "status", tags: "tags" }),
    ...mapState("tagData", { tagDataStatus: "status", keys: "keys" }),
    ...mapState("companies", {
      companyUsers: "companyUsers",
      companyStatus: "status",
    }),
    ...mapState("alarms", {
      alarmStatus: "status",
      currentAlarm: "currentAlarm",
    }),
  },

  methods: {
    ...mapActions("tag", ["getTags", "getKeysForTag"]),
    ...mapActions("companies", ["getCompanyUsers"]),
    ...mapActions("alarms", [
      "updateAlarm",
      "getAlarmById",
      "removeAlarm",
      "duplicateAlarm",
    ]),

    isNotObject(data) {
      return typeof data !== "object";
    },

    async deleteAlarm() {
      this.dialog = true;
    },

    async deleteItem() {
      await this.removeAlarm(this.currentAlarm.alarmId);

      this.$router.push("/alarm");
    },

    async copyAlarm() {
      await this.duplicateAlarm(this.currentAlarm.alarmId);

      this.$router.push("/alarm");
    },

    // eslint-disable-next-line
    async handleSubmit(e) {
      if (!this.$refs.form.validate()) {
        this.valid = false;
        return;
      }

      this.submitted = true;

      const {
        name,
        priority,
        alertType,
        autoAcknowledge,
        alarmDelay,
        active,
        tagDataKey,
        tag,
        message,
        alarmContacts,
        activeDays,
        limitLow,
        limitHigh,
        logicalOperator
      } = this;

      var payload = {
        name: name,
        message: message,
        priority: priority,
        alertType: alertType,
        autoAcknowledge: autoAcknowledge,
        tagDataKey: tagDataKey,
        limitLow: limitLow,
        limitHigh: limitHigh,
        contacts: alarmContacts,
        active: active,
        tagDeveui: tag,
        alarmDelay: alarmDelay,
        activeDays: [],
        alarmConditions: this.advancedAlarms,
        advanced: this.advanced,
        logicalOperator: logicalOperator,
      };

      if (this.signalType == 1 && this.digitalType == 0) {
        payload.limitHigh = 0;
        payload.limitLow = null;
      } else if (this.signalType == 1 && this.digitalType == 1) {
        payload.limitHigh = null;
        payload.limitLow = 1;
      }

      activeDays.forEach((day) => {
        payload.activeDays.push({ day: day });
      });

      if (this.valid) {
        await this.updateAlarm({
          payload: payload,
          alarmId: this.currentAlarm.alarmId,
        });
        this.$router.push("/alarm");
      }
    },

    getDateName(dayInt) {
      switch (dayInt) {
        case 0:
          return i18n.t("common.days.sunday");
        case 1:
          return i18n.t("common.days.monday");
        case 2:
          return i18n.t("common.days.tuesday");
        case 3:
          return i18n.t("common.days.wednesday");
        case 4:
          return i18n.t("common.days.thursday");
        case 5:
          return i18n.t("common.days.friday");
        case 6:
          return i18n.t("common.days.saturday");
      }

      return;
    },

    resolveSignalType() {
      if (!this.limitLow && this.limitHigh == 0) {
        this.signalType = 1;
        this.digitalType = 0;
      } else if (!this.limitHigh && this.limitLow == 1) {
        this.signalType = 1;
        this.digitalType = 1;
      } else {
        this.signalType = 0;
      }
    },
  },

  async created() {
    if (
      this.currentAlarm !== undefined &&
      this.currentAlarm.alarmId !== this.$route.params.id
    )
      await this.getAlarmById(this.$route.params.id);

    this.getTags();
    this.getCompanyUsers();

    this.alarmId = this.currentAlarm.alarmId;
    this.name = this.currentAlarm.name;
    this.priority = this.currentAlarm.priority;
    this.alertType = this.currentAlarm.alertType;
    this.autoAcknowledge = this.currentAlarm.autoAcknowledge;
    this.alarmDelay = this.currentAlarm.alarmDelay;
    this.active = this.currentAlarm.active;
    this.tagDataKey = this.currentAlarm.tagDataKey;
    this.tag = this.currentAlarm.tag ? this.currentAlarm.tag.deveui : null;
    this.message = this.currentAlarm.message;
    this.limitLow = this.currentAlarm.limitLow;
    this.limitHigh = this.currentAlarm.limitHigh;
    this.logicalOperator = this.currentAlarm.logicalOperator;
    this.alarmContacts = this.currentAlarm.alarmContacts.map((e) => {
      return e.user.userId;
    });

    this.activeDays = this.currentAlarm.activeDays.map((e) => {
      return e.day;
    });

    this.advancedAlarms = this.currentAlarm.advancedAlarms.slice(0);
    this.advanced = this.currentAlarm.advanced;

    this.resolveSignalType();
  },

  watch: {
    tag(val) {
      if (val != null) this.getKeysForTag({ tagId: val });
    },
  },

  components: {
    DeleteDialog,
    ConditionTree,
  },
};
</script>

<style></style>
